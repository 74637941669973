<template>
  <div>
    <b-card>
      <div class="d-flex flex-wrap justify-content-between pt-2">
        <div class="d-flex flex-wrap">
          <h4>{{ $t("settings.my-communities.title") }}</h4>
          <b-badge v-if="items && items.length" variant="primary" class="align-self-start ml-50">
            {{ items.length }}
          </b-badge>
        </div>
        <v-select
          id="type"
          v-model="createForm.type"
          class="mb-1 text-capitalize d-none d-lg-block"
          :options="typesSelect"
          :get-option-label="(option) => option.name"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          label="name"
          style="width: 200px"
          :clearable="false"
          @input="filter()"
        />
        <v-select
          id="type"
          v-model="createForm.type"
          class="mb-1 text-capitalize d-lg-none w-100"
          :options="typesSelect"
          :get-option-label="(option) => option.name"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          label="name"
          style="width: 200px"
          :clearable="false"
          @input="filter()"
        />
      </div>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <!-- Search -->
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-lg-none mr-2 my-1 mb-lg-3"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-none d-lg-block mx-auto mr-2 mb-1 mb-lg-3"
          style="width:450px;"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <div v-if="items && items.length > 0" class="mt-2 d-flex flex-wrap justify-content-around w-100">
          <div
            v-for="(collective, index) in items"
            :key="index"
            class="x-scroll-element mx-1 mb-2"
          >
            <b-link class="text-dark" target="_blank" :href="getCollectiveUrl(collective)">
              <b-card class="small-card">
                <card-thumbnail :src="thumbnailSrc(collective.community)" :placeholder="placeholder" />
                <h5 class="mt-1 ">
                  {{ titleShort(collective.community) }}
                </h5>
                <p class="m-0 small-card-headline text-secondary html-text-ellipsis-1">
                  {{ headline(collective.community) }}
                </p>
                <small v-if="collective.community && collective.community.mainType"><b-badge pill :variant="getColor(collective.community.mainType)" class="ml-25">{{collective.community.mainType}}</b-badge></small>
              </b-card>
            </b-link>
          </div>
        </div>
        <!-- Container without data: Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col v-if="placeholder" cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t('available.message', { itemName: $t('settings.my-communities.title') }) }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import ListContainer from '@core/constants/ListContainer';
import CommunitiesPlaceholder from '@/assets/images/placeholders/light/communities.svg';
import Service from '@/config/service-identifiers';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import CardThumbnail from '@core/components/card-thumbnail/CardThumbnail.vue';
import vSelect from 'vue-select';

export default {
  name: 'MySubcommunities',
  components: {
    CardThumbnail,
    vSelect,
  },
  data() {
    return {
      createForm: {
        type: {},
        name: '',
      },
      isLoading: false,
      communities: [],
      searchInput: null,
      results: null,
    };
  },
  computed: {
    typesSelect() {
      return [
        { type: 'all', name: this.$t('display-type-options.show-all') },
        { type: 'Community', name: this.$t('my-nectios.welcome.types-options.community') },
        { type: 'Subcommunity', name: this.$t('my-nectios.welcome.types-options.subcommunity') },
        { type: 'Event', name: this.$t('my-nectios.welcome.types-options.event') },
        { type: 'Course', name: this.$t('my-nectios.welcome.types-options.course') },
        { type: 'Project', name: this.$t('my-nectios.welcome.types-options.project') },
        { type: 'Organization', name: this.$t('my-nectios.welcome.types-options.organization') },
        { type: 'Challenge', name: this.$t('my-nectios.welcome.types-options.challenge') },
        { type: 'Workspace', name: 'Workspace' },
      ];
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    items() {
      const itemsToIterate = this.results || this.communities;
      return itemsToIterate;
    },
  },
  async created() {
    this.createForm.type = this.typesSelect[0];
    await this.fetchData();
  },
  methods: {
    titleShort(collective) {
      const title = translateTranslationTable(this.$store.state.locale.currentLocale, collective?.name);
      if (title.length < 19) {
        return title;
      }
      return this.trimString(title);
    },
    headline(collective) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, collective?.headline);
    },
    getCollectiveUrl(collective) {
      return process.env.VUE_APP_COMMUNITY_TEMPLATE_URL
        .replace('{subdomain}', collective?.community?.subdomain || 'app')
        .replace('{slug}', collective?.community?.slug);
    },
    trimString(string) {
      const trimmedString = string.substr(0, 19);
      return `${trimmedString}...`;
    },
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.$service[Service.BackendClient].get('/communityMembers', {
        params: {
          communitySlug: this.$store.getters.mainCollective?.slug || this.$store.getters.currentCollective.slug,
          username: this.loggedMember.username,
          isForProfile: true,
        },
      });
      this.communities = response.data.data[0].subCommunities;
      this.isLoading = false;
    },
    translateTranslationTable,
    thumbnailSrc(collective) {
      return collective?.header?.banner_url || collective?.bgURL || CommunitiesPlaceholder;
    },
    searchItems() {
      const searchTerm = this.searchInput.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.communities?.filter(
        (item) => {
          if (this.createForm.type.type === 'all') {
            if (typeof item?.community?.name === 'string') {
              return item?.community?.name.toLowerCase().includes(searchTerm);
            }
            return Object.values(item?.community?.name).map(item => item.includes(searchTerm))
          }
          return item?.community?.mainType === this.createForm.type.type && item?.community?.name.toLowerCase().includes(searchTerm);
        },
      );
    },
    getColor(type) {
      const types = {
        Event: 'success',
        Community: 'primary',
        Project: 'info',
        Challenge: 'warning',
        Workspace: 'dark',
        Service: 'light',
        Subcommunity: 'secondary',
        Organization: 'danger',
      };
      return types[type] || 'secondary';
    },
    filter() {
      this.results = this.communities?.filter(
        (item) => {
          if (this.createForm.type.type === 'all') {
            return item;
          }
          return item?.community?.mainType === this.createForm.type.type
        },
      );
    },
  },
};
</script>
